import { Layout } from '@components/layout';
import { RemindPasswordTitle, ResetPasswordForm } from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface ResetPasswordPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({
  pageContext,
  location
}) => {
  const { page } = pageContext;
  const token = new URLSearchParams(location.search).get('token');

  return (
    <Layout {...page}>
      <RemindPasswordTitle />
      <ResetPasswordForm token={token} />
    </Layout>
  );
};

export default ResetPasswordPage;
